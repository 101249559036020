<template>
    <div>
      <Header/>
      <div class="content_text_place">
        <!-- <router-link :to="{path:'/'}">
          <img class="content_img_1_place" src="../../assets/content/logo_s.png">
        </router-link> -->
        <img class="content_img_2_place" src="../../assets/task/time_icon.png">
        <div>{{task}}</div>
        <div class="rank_div_place">
          <!-- <router-link :to="{path:'/task/grade_table'}"> -->
          <img class="rank_icon_ss_place" src="../../assets/task/rank_icon.png">
          <!-- </router-link> -->
        </div>
      </div>
      <div style="padding: 20px; text-align:left;">
          <p style="font-size: 24px;color:#fff;">{{title}}</p>
          <p style="font-size: 14px;color:#fff;">{{content}}</p>
      </div>

      <div style="padding: 17px;">
          <div class="grace-forms_place" v-for="(item,index) in from" :key="index">
            <div class="grace-form-items_place grace-border-bs_place">
              <div class="grace-form-label_place" style="line-height: 6vh;">客戶姓名</div>
              <div class="grace-form-body_place grace-rows_place grace-flex-vtop_place">
                <input type="text" v-model="item.customer_name" :placeholder="client_name" class="input_place border-style-box_place">
              </div>
            </div>
            <div class="grace-form-items_place grace-border-bs_place">
              <div class="grace-form-label_place" style="line-height: 6vh;">地址</div>
              <div class="grace-form-body_place grace-rows_place grace-flex-vtop_place">
                <textarea v-model="item.address" :placeholder="address_text" class="input_place border-style-box_place"></textarea>
              </div>
            </div>
            
            <!-- <div class="grace-form-items_place grace-border-bs_place">
              <div class="grace-form-label_place" style="line-height: 6vh;"></div>
              <div style="padding-left: 1vw;display: flex;width: 100%;">
                <div style="width:10%;height:100%;"><img style="width:20px;height:20px;" src="../../assets/task/wz.png"></div>
                <div style="width:40%;height:100%;font-size:14px;color:#fff;">{{location}}</div>
                <div style="width:50%;height:100%;text-align:right;"><img style="width:24px;height:24px;" src="../../assets/task/common_task/radio.png"></div>
              </div>
            </div> -->
            
          </div>
          <div class="center-form_place">
            <!-- <button style="margin: 0px; padding: 0px; width: 72px; height: 34px;"></button> -->
            <img src="../../assets/task/done_btn.png" style="margin: 0px; padding: 0px; width: 72px; height: 34px;" @click="save">
          </div>
          <div style="height:40px;width:100%;"></div>
      </div>
    </div>
</template>

<style>
p{
  margin:0;
}
.rank_icon_ss_place{
  height: 40px;
  margin-right: 8px;
  width: 40px;
}
.center-form_place{
  margin-top: 10vh;
}
::-webkit-input-placeholder{
    color:#ddd;
}
.input_place{
  width: 100%;
  padding: 3px 3px;
  font-size: 15px;
  border: none;
  background-color: rgb(196, 155, 108) !important;
  min-height: 1.4em;
  color:#fff;
}
.grace-form-body_place{
  padding: 10px 0!important;
  border: 1px solid hsla(0,0%,100%,.8);
  color: #fff;
  width: 80%;
  margin-left: 9px;
  overflow: hidden;
}
  .grace-form-label_place{
    text-align: left;
    width: 20%;
    color: #fff!important;
    font-size: 13px;
  }
  .grace-form-items_place{
    color: #222;
    margin-top: 15px;
    display: flex;
  }
  .grace-forms_place{
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .content_text_place{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
    border-bottom: 1px solid #fff
  }
  .content_img_1_place{
    margin-right: 13px;
    width: 60px;
    height: 58px;
  }
  .content_img_2_place{
    width: 50px;
    height: 48px;
    margin-right: 8px;
  }
  .rank_div_place{
  margin-left: auto;
  margin-right: 17px;
  display: flex;
}
</style>
<script>
import Header from "../Header";
import {apiDetailClocks,apiCreateUserClockLogsLocation,apiUpdateUserClockLogs} from '@/request/api';
export default {
  components:{Header},
  data(){
    return {
      title:'',
      content:'',
      visits_number:'',
      customer_name:'',
      latitude:'',
      longitude:'',
      from:[],
      froms:[],
      todayUserClockLog_id:0,
      user_id:'',
      task:'',
      location:'',
      address_text:'',
      client_name:'',
    }
  },
  created(){
    let id = this.$route.params.id;
    this.user_id = this.$store.state.user_id;
    let lang_id = this.$store.state.lang_id;

    if(lang_id == 1){
      this.task = this.$zh_ft('task');
      this.location = this.$zh_ft('location');
      this.address_text = this.$zh_ft('address_text');
      this.client_name = this.$zh_ft('client_name');
    }else{
      this.task = this.$zh_jt('task');
      this.location = this.$zh_jt('location');
      this.address_text = this.$zh_jt('address_text');
      this.client_name = this.$zh_jt('client_name');
    }
    if(id && id>0){
      apiDetailClocks(id,{
        user_id:this.user_id
      }).then(res=>{
        if(res.data){
          this.title = res.data.title;
          this.content = res.data.sub_title;
          if(res.data.todayUserClockLog.length > 0){
            this.froms = res.data.todayUserClockLog[0].meeting_list;
            this.todayUserClockLog_id = res.data.todayUserClockLog[0].id;
          }
          if(res.data.visits_number > 1){
            for(let i=0; i<res.data.visits_number; i++){
              this.from.push({
                customer_name:'',
                latitude:'116.086915',
                longitude:'116.086915',
                address:'',
              });
            }
          }else{
            this.from.push({
                customer_name:'',
                latitude:'116.086915',
                longitude:'116.086915',
                address:'',
              });
          }

          let from = this.from;
          let froms = this.froms;
          if(from.length > 0){
            for(let i=0; i<from.length; i++){
              for(let j=0; j<froms.length; j++){
                if(i == j){
                  from[i].customer_name = froms[j].customer_name;
                  from[i].address = froms[j].address;
                }
              }
            }
          }
        }
      }).catch(error=>{
        console.log(error);
      })
    }
  },
  methods:{
    save(){
      let from = this.from;
      let id = this.$route.params.id;
      if(from.length > 0){
        // for(let i=0; i<from.length; i++){
        //   if(!from[i].customer_name){
        //     this.$message.error('第'+(i+1)+'行客戶姓名不能爲空');
        //     return false;
        //   }
        // }
        if(id && id>0){
          // 大於0説明有數據是編輯
          if(this.todayUserClockLog_id > 0){
            apiUpdateUserClockLogs(this.todayUserClockLog_id,{
              lang_id:this.$store.state.lang_id,
              meeting_list:this.from,
              address:this.address
            }).then(res=>{
              if(res.code == 200){
                this.$message.success('修改成功');
                this.$router.push({path:'/task'});
              }else{
                this.$message.error(res.message);
                return false;
              }
            }).catch(error=>{
              console.log(error);
            })
          }else{
            apiCreateUserClockLogsLocation({
              clock_id:id,
              meeting_list:this.from
            }).then(res=>{
              if(res.code == 200){
                this.$message.success('打卡成功');
                this.$router.push({path:'/task'});
              }else{
                this.$message.error(res.message);
                return false;
              }
            }).catch(error=>{
              console.log(error);
            })
          } 
        }
      }
    },
    activation_record(){
      this.$router.push({
        path:'/my/activation_record',
        query:{
          id:this.$route.params.id,
        }
      });
    }
  }
}
</script>
<style>
</style>